import React from "react";

export const IsotipoLogo = React.forwardRef((props, ref) => (
    <svg {...props} width="1em" height="1em" ref={ref} viewBox="0 0 79.5 64" >
        <g>
            <path fill="#3370F3" d="M22.3,51.2c-5.9-1.5-10.7-5.4-13.4-10.7c0.6,6,3.3,11.5,7.2,15.6l7-4.1C22.9,51.7,22.6,51.5,22.3,51.2
            L22.3,51.2z"/>
            <path fill="#3370F3" d="M34.3,56.3H16.4C21.1,61,27.6,64,34.8,64c3.1,0,6.1-0.6,8.9-1.6C39.8,61.7,36.5,59.4,34.3,56.3z" />
        </g>
        <path fill="#3370F3" d="M42,12.6c-2.3-0.7-4.7-1-7.2-1c-0.2,0-0.5,0-0.7,0c1.9,0.7,3.7,1.6,5.3,2.9C40.2,13.8,41.1,13.2,42,12.6z" />
        <path fill="#3370F3" d="M59.6,40.2c0.3,0.6,0.6,1.2,0.8,1.8c0.1-0.9,0.2-1.8,0.3-2.7C60.4,39.6,60,39.9,59.6,40.2L59.6,40.2z" />
        <path fill="#3370F3" d="M27.3,51.8c-0.4,0-0.9,0-1.3-0.1c0.2,0.1,0.4,0.2,0.5,0.3l-4.2,2.4h10.9c-0.5-1-1-2.1-1.2-3.3
        C30.4,51.6,28.9,51.8,27.3,51.8L27.3,51.8z"/>
        <path fill="#33B9D8" d="M60.8,37.8c0,0.5,0,1,0,1.5c3.5-3,5.7-7.5,5.7-12.4c0-9.1-7.3-16.4-16.3-16.4c-3,0-5.7,0.8-8.1,2.2
        C52.8,15.8,60.8,25.9,60.8,37.8z"/>
        <path fill="#00A8CE" d="M45.3,21.2c1.6,2.9,2.5,6.3,2.5,9.9c0,0.5,0,0.9-0.1,1.4c2.1,0.2,4,0.8,5.8,1.7C52.8,28.7,49.7,24.1,45.3,21.2
        L45.3,21.2z"/>
        <path fill="#0A9DD5" d="M43.2,18.1C50.1,21,55,27.6,55.4,35.4c1.7,1.3,3.2,2.9,4.2,4.8c0.4-0.3,0.8-0.6,1.1-0.9c0-0.5,0-1,0-1.5
        c0-11.9-7.9-22-18.8-25.1c-0.9,0.5-1.8,1.1-2.6,1.8C40.8,15.5,42.1,16.8,43.2,18.1z"/>
        <path fill="#FF5362" d="M40.2,7.7c2.1,0,3.8-1.7,3.8-3.8S42.3,0,40.2,0s-3.8,1.7-3.8,3.8S38.1,7.7,40.2,7.7z" />
        <path fill="#8247CD" d="M34.1,11.6c-2.1-0.8-4.4-1.2-6.8-1.2c-11.4,0-20.5,9.3-20.5,20.7c0,3.4,0.8,6.5,2.2,9.3
        c-0.1-0.9-0.1-1.8-0.1-2.7C8.8,23.6,20,12,34.1,11.6L34.1,11.6z"/>
        <path fill="#5133C5" d="M35.6,29.8c-0.5,0-1,0.1-1.4,0.2c-2,0.7-3.5,2.6-3.5,4.9c0,0.4,0,0.8,0.1,1.2l0.1,0.5c0.1,0.2,0.1,0.4,0.2,0.6
        c0.3,0.6,0.6,1.5,1,2.1c0.3,0.6,0.5,1.4,0.6,2c0.9-2.1,2.3-3.9,3.9-5.4c1.1-1,2.3-1.7,3.6-2.3C39.8,31.5,37.9,29.9,35.6,29.8
        L35.6,29.8z M34.1,32.3c-0.4,0.3-2.4,2.2-0.3,5.6c0.2,0.2,0.1,0.5-0.2,0.7c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0-0.3-0.1-0.4-0.2
        c-2.6-4.1,0-6.5,0.5-6.9c0.2-0.2,0.5-0.1,0.7,0.1S34.3,32.1,34.1,32.3L34.1,32.3z"/>
        <path fill="#592ACB" d="M45.3,21.2c-2.7-1.7-5.8-2.7-9.1-2.8c-0.2,0-0.4,0-0.6,0c-10,0-18.1,8.1-18.1,18.1c0,6.5,3.4,12.1,8.5,15.3
        c0.4,0,0.9,0.1,1.3,0.1c1.6,0,3.2-0.2,4.7-0.6c-0.3-1.2-0.5-2.4-0.5-3.7c0-1.5,0.2-2.9,0.6-4.3c-0.1-0.1-0.2-0.2-0.2-0.4v-0.2
        c0,0,0-0.2-0.1-0.6c-0.1-0.9-0.3-1.7-0.5-2.1c-0.4-0.7-0.7-1.6-1-2.2c-0.1-0.2-0.2-0.4-0.3-0.7c0,0,0,0,0,0l0,0l-0.2-0.6
        c-0.1-0.5-0.2-1-0.2-1.5c0-2.8,1.8-5.2,4.3-6c0.5-0.1,1-0.2,1.6-0.2c2.7,0,5,1.9,5.7,4.6c1.6-0.6,3.4-1,5.2-1c0.4,0,0.8,0,1.2,0.1
        c0-0.5,0.1-0.9,0.1-1.4C47.8,27.6,46.9,24.2,45.3,21.2L45.3,21.2z M24.4,35.5c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h2.9
        c0.3,0,0.5,0.2,0.5,0.5s-0.2,0.5-0.5,0.5H24.4z M30,40.9c0.2,0.2,0.2,0.5,0,0.7L28,43.7c-0.1,0.1-0.2,0.2-0.4,0.2s-0.3,0-0.4-0.2
        c-0.2-0.2-0.2-0.5,0-0.7l2.1-2.1C29.5,40.7,29.8,40.7,30,40.9z M30,29.6c-0.1,0.1-0.2,0.1-0.4,0.1s-0.3,0-0.4-0.1l-2.1-2.1
        c-0.2-0.2-0.2-0.5,0-0.7s0.5-0.2,0.7,0l2.1,2.1C30.2,29.1,30.2,29.4,30,29.6z M36.1,26.5c0,0.3-0.2,0.5-0.5,0.5s-0.5-0.2-0.5-0.5
        v-2.9c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5V26.5z M43.9,27.6l-2.1,2.1c-0.1,0.1-0.2,0.1-0.4,0.1s-0.3,0-0.4-0.1
        c-0.2-0.2-0.2-0.5,0-0.7l2.1-2.1c0.2-0.2,0.5-0.2,0.7,0S44.1,27.4,43.9,27.6z"/>
        <path fill="#592ACB" d="M39.4,14.5c-1.6-1.2-3.4-2.2-5.3-2.9C20,12,8.8,23.6,8.8,37.8c0,0.9,0.1,1.8,0.1,2.7c2.7,5.3,7.5,9.3,13.4,10.7
        c-4-3.6-6.6-8.9-6.6-14.8c0-11,8.9-19.9,19.9-19.9c0.6,0,1.2,0,1.7,0.1c2.1,0.2,4.1,0.7,5.9,1.5C42.2,16.8,40.9,15.5,39.4,14.5
        L39.4,14.5z"/>
        <path fill="#FF7581" d="M43.7,62.4c0.9,0.2,1.9,0.3,2.8,0.3c8.3,0,15-6.8,15-15.1c0-2-0.4-3.9-1.1-5.6C58.9,51.4,52.4,59.2,43.7,62.4
        L43.7,62.4z"/>
        <path fill="#D6597F" d="M53.5,34.2c-1.8-0.9-3.7-1.5-5.8-1.7c-0.2,3.6-1.4,6.9-3.2,9.8c-0.2,0.3-0.4,0.6-0.6,0.9
        c0.1,0.2,0.1,0.5-0.1,0.6c-0.1,0.1-0.2,0.2-0.4,0.2c0,0-0.1,0-0.1,0c-2.8,3.6-6.8,6.3-11.4,7.4c0.3,1.2,0.7,2.2,1.2,3.3h2.4
        c7.7,0,14.2-4.8,16.8-11.5c0.8-2,1.3-4.3,1.3-6.6C53.7,35.7,53.6,34.9,53.5,34.2L53.5,34.2z"/>
        <path fill="#D6597F" d="M59.6,40.2c-1-1.9-2.5-3.5-4.2-4.8c0,0.4,0,0.7,0,1.1c0,2.2-0.4,4.2-1,6.2c-2.6,7.9-10.1,13.7-18.9,13.7h-1.3
        c2.2,3.1,5.5,5.3,9.4,6.1c8.7-3.2,15.3-11,16.8-20.5C60.2,41.3,59.9,40.7,59.6,40.2L59.6,40.2z"/>
        <path fill="#DC4C75" d="M46.5,32.4c-1.8,0-3.6,0.3-5.2,1c0.1,0.5,0.2,1.1,0.2,1.7c0,0.5,0,1-0.2,1.4l-0.2,0.6l0,0c0,0,0,0,0,0.1
        c-0.1,0.2-0.2,0.5-0.3,0.7c-0.2,0.5-0.5,1.3-0.8,1.9c0,0.1-0.1,0.2-0.1,0.2c-0.5,0.9-0.6,2.5-0.6,2.7v0.2c0,0.3-0.2,0.5-0.5,0.5
        h-6.4c-0.1,0-0.2,0-0.3-0.1c-0.4,1.3-0.6,2.8-0.6,4.3c0,1.3,0.2,2.5,0.5,3.7c4.6-1.1,8.6-3.7,11.4-7.4c-0.1,0-0.2-0.1-0.2-0.1
        l-2.1-2.1c-0.2-0.2-0.2-0.5,0-0.7s0.5-0.2,0.7,0l2.1,2.1c0,0,0,0.1,0.1,0.1c0.2-0.3,0.4-0.6,0.6-0.9c1.8-2.9,2.9-6.2,3.2-9.8
        C47.3,32.5,46.9,32.4,46.5,32.4L46.5,32.4z M38,47h-4.8c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5H38c0.3,0,0.5,0.2,0.5,0.5
        S38.2,47,38,47z M38.4,45.8h-5.6c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h5.6c0.3,0,0.5,0.2,0.5,0.5S38.7,45.8,38.4,45.8z
         M38.4,44.6h-5.6c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h5.6c0.3,0,0.5,0.2,0.5,0.5S38.7,44.6,38.4,44.6z M46.8,35.5h-2.9
        c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h2.9c0.3,0,0.5,0.2,0.5,0.5S47.1,35.5,46.8,35.5z"/>
        <path fill="#DC4C75" d="M40.3,33.8c-1.3,0.6-2.5,1.4-3.6,2.3c-1.7,1.5-3,3.3-3.9,5.4c0,0.2,0,0.3,0.1,0.5c0,0.1,0,0.3,0,0.4h5.5
        c0-0.6,0.2-2,0.7-2.9c0.1-0.1,0.2-0.3,0.2-0.5c0.3-0.6,0.5-1.2,0.7-1.6c0.1-0.2,0.2-0.4,0.2-0.6c0,0,0,0,0,0l0.2-0.5
        c0.1-0.4,0.1-0.8,0.1-1.2C40.5,34.6,40.4,34.2,40.3,33.8z"/>
        <path fill="#6319C1" d="M1.8,35.9c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8S2.8,35.9,1.8,35.9z" />
        <path fill="#FF8792" d="M67,40.8c-1.2,0-2.1,0.9-2.1,2.1S65.8,45,67,45s2.1-0.9,2.1-2.1S68.1,40.8,67,40.8z" />
        <path fill="#4D82F5" d="M75.1,30.7c-2.4,0-4.4,2-4.4,4.4s2,4.4,4.4,4.4s4.4-2,4.4-4.4S77.5,30.7,75.1,30.7z" />
        <ellipse fill="#00A8CE" cx="9.5" cy="59.7" rx="2.3" ry="2.3" />
        <path fill="#FFFFFF" d="M55.4,35.4C55,27.6,50.1,21,43.2,18.1c-1.8-0.8-3.8-1.3-5.9-1.5c-0.6,0-1.2-0.1-1.7-0.1
        c-11,0-19.9,8.9-19.9,19.9c0,5.9,2.5,11.1,6.6,14.8c0.3,0.2,0.6,0.5,0.9,0.7l-7,4.1l-0.4,0.3h19.9c8.8,0,16.3-5.7,18.9-13.7
        c0.6-2,1-4,1-6.2C55.4,36.1,55.4,35.7,55.4,35.4z M35.6,54.6H22.3l4.2-2.4c-0.2-0.1-0.4-0.2-0.5-0.3c-5.1-3.2-8.5-8.9-8.5-15.3
        c0-10,8.1-18.1,18.1-18.1c0.2,0,0.4,0,0.6,0c3.4,0.1,6.5,1.1,9.1,2.8c4.4,2.8,7.5,7.5,8.2,13c0.1,0.8,0.2,1.5,0.2,2.3
        c0,2.3-0.5,4.6-1.3,6.6C49.8,49.8,43.2,54.6,35.6,54.6z"/>
        <g>
            <path fill="#FFFFFF" d="M29.3,40.9L27.2,43c-0.2,0.2-0.2,0.5,0,0.7c0.1,0.1,0.2,0.2,0.4,0.2s0.3,0,0.4-0.2l2.1-2.1
            c0.2-0.2,0.2-0.5,0-0.7S29.5,40.7,29.3,40.9z"/>
            <path fill="#FFFFFF" d="M33.4,31.5c-0.5,0.4-3.1,2.8-0.5,6.9c0.1,0.2,0.3,0.2,0.4,0.2c0.1,0,0.2,0,0.3-0.1c0.2-0.2,0.3-0.5,0.2-0.7
            c-2.1-3.3-0.2-5.2,0.3-5.6c0.2-0.2,0.2-0.5,0.1-0.7S33.6,31.3,33.4,31.5L33.4,31.5z"/>
            <path fill="#FFFFFF" d="M29.3,29.6c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1c0.2-0.2,0.2-0.5,0-0.7L28,26.8c-0.2-0.2-0.5-0.2-0.7,0
            s-0.2,0.5,0,0.7C27.3,27.5,29.3,29.6,29.3,29.6z"/>
            <path fill="#FFFFFF" d="M27.8,35c0-0.3-0.2-0.5-0.5-0.5h-2.9c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h2.9
            C27.6,35.5,27.8,35.3,27.8,35z"/>
            <path fill="#FFFFFF" d="M41.5,29.8c0.1,0,0.3,0,0.4-0.1l2.1-2.1c0.2-0.2,0.2-0.5,0-0.7c-0.2-0.2-0.5-0.2-0.7,0L41.1,29
            c-0.2,0.2-0.2,0.5,0,0.7C41.2,29.8,41.4,29.8,41.5,29.8z"/>
            <path fill="#FFFFFF" d="M35.6,27.1c0.3,0,0.5-0.2,0.5-0.5v-2.9c0-0.3-0.2-0.5-0.5-0.5s-0.5,0.2-0.5,0.5v2.9
            C35.1,26.8,35.3,27.1,35.6,27.1z"/>
            <path fill="#FFFFFF" d="M38.4,43.6h-5.6c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h5.6c0.3,0,0.5-0.2,0.5-0.5S38.7,43.6,38.4,43.6z" />
            <path fill="#FFFFFF" d="M38.4,44.8h-5.6c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h5.6c0.3,0,0.5-0.2,0.5-0.5S38.7,44.8,38.4,44.8z" />
            <path fill="#FFFFFF" d="M38,46h-4.8c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5H38c0.3,0,0.5-0.2,0.5-0.5S38.2,46,38,46z" />
            <path fill="#FFFFFF" d="M43.9,43.1L41.9,41c-0.2-0.2-0.5-0.2-0.7,0s-0.2,0.5,0,0.7l2.1,2.1c0.1,0.1,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0
            c0.1,0,0.3,0,0.4-0.2C44.1,43.6,44.1,43.3,43.9,43.1C44,43.1,44,43.1,43.9,43.1z"/>
            <path fill="#FFFFFF" d="M46.8,34.5h-2.9c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h2.9c0.3,0,0.5-0.2,0.5-0.5S47.1,34.5,46.8,34.5z" />
            <path fill="#FFFFFF" d="M41.2,37.1L41.2,37.1l0.2-0.6c0.1-0.5,0.2-1,0.2-1.4c0-0.6-0.1-1.1-0.2-1.7c-0.7-2.6-3-4.6-5.7-4.6
            c-0.5,0-1.1,0.1-1.6,0.2c-2.5,0.7-4.3,3.1-4.3,6c0,0.5,0,1,0.2,1.5l0.2,0.6l0,0c0,0,0,0,0,0c0.1,0.2,0.2,0.5,0.3,0.7
            c0.2,0.6,0.6,1.5,1,2.2c0.2,0.5,0.4,1.2,0.5,2.1c0,0.3,0,0.5,0,0.6v0.2c0,0.2,0.1,0.3,0.3,0.4c0.1,0,0.2,0.1,0.3,0.1h6.4
            c0.3,0,0.5-0.2,0.5-0.5v-0.2c0-0.2,0.1-1.8,0.6-2.7c0-0.1,0.1-0.2,0.1-0.2c0.3-0.6,0.6-1.4,0.8-1.9C41,37.6,41.1,37.3,41.2,37.1
            C41.2,37.1,41.2,37.1,41.2,37.1L41.2,37.1z M40.3,36.2l-0.2,0.5c0,0,0,0,0,0c-0.1,0.2-0.2,0.4-0.2,0.6c-0.2,0.5-0.5,1.1-0.7,1.6
            c-0.1,0.2-0.2,0.3-0.2,0.5c-0.5,0.9-0.6,2.2-0.7,2.9h-5.5c0-0.1,0-0.2,0-0.4s0-0.3-0.1-0.5c-0.1-0.7-0.3-1.4-0.6-2
            c-0.3-0.7-0.7-1.5-1-2.1C31.1,37.2,31,37,31,36.8l-0.1-0.5c-0.1-0.4-0.1-0.8-0.1-1.2c0-2.3,1.5-4.3,3.5-4.9
            c0.5-0.1,0.9-0.2,1.4-0.2c2.3,0,4.2,1.7,4.7,3.9c0.1,0.4,0.2,0.8,0.2,1.3C40.5,35.4,40.4,35.8,40.3,36.2L40.3,36.2z"/>
        </g>
    </svg>
));
