import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form'
import Spiner from '../components/Spiner';
import { customSwall } from '../components/CustomSwal';
import { useUserData } from '../api/useUserData'
import { useState } from 'react';
import { urlServer } from '../config/config';
import HeaderSection from '../components/utils/HeaderSection';
import { Toggle } from 'rsuite';

export default function EnviarCorreo() {

    const [ tieneVariables, setTieneVariables ] = useState(false)
    const [ params, setParams ] = useSearchParams();
    const [ loadingS, setLoadingS ] = useState(false)
    const user = JSON.parse(localStorage.getItem('userData'))
    const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm({
        defaultValues: {
          email: params.get('send') ?? '',
          empresa: params.get('empresa') ?? '',
          nombre: params.get('name') ?? '',
          apellido: params.get('last_name') ?? '',
          guardar: user.settings.guardar_contacto ?? true 
        },
    })

    const { data, loading } = useUserData(`${ urlServer }/api/mail-config-user`, user.token);

    const onSubmit = handleSubmit(data => {
        if (tieneVariables && data.saludo === '' && data.nombre === '' && data.empresa === '' && data.apellido === '') {
            customSwall.fire({
                title: 'Error!',
                text: 'Debes incluir por lo menos el saludo, el nombre, el apellido, o el nombre de la empresa',
                icon: 'error',
                confirmButtonText: 'OK'
            })
            return
        }
        customSwall.fire({
            icon: 'question',
            title: 'Está correcto?',
            text: data.email,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Si, enviar correo'
        }).then((result) => {
            if (result.isConfirmed) {
                enviar()
            }
        }) 
    })

    function updateView() {
        if (watch('hostMail') === '' || watch('plantilla') === '') {
            if (watch('plantilla') === '') {
                let blob = new Blob(['<h1>Selecciona una plantilla</h1>'], {type: 'text/html'});
                document.querySelector('iframe').src = URL.createObjectURL(blob);
                setTieneVariables(false)
            }
            return
        }
        let url = `${ urlServer }/api/prev-mail`;
        let formData = new FormData();
        formData.append('token', user.token)
        formData.append('hostMail', watch('hostMail'))
        formData.append('plantilla', watch('plantilla'))
        formData.append('nombre', watch('nombre'))
        formData.append('apellido', watch('apellido'))
        formData.append('empresa', watch('empresa'))
        formData.append('saludo', watch('saludo'))
        fetch(url, {
            method: 'POST',
            body: formData,
            }).then(response => response.json())
            .then(data => {
                let blob = new Blob([data.plantilla.html], {type: 'text/html'});
                document.querySelector('iframe').src = URL.createObjectURL(blob);
                document.querySelector('.resumen').innerHTML = data.resumen
                if (watch('plantilla') !== '') {
                    setValue('asunto', data.plantilla.asunto)
                    setTieneVariables(data.plantilla.tieneVariables)
                }
        });
    }

    function enviar() {
        let url = `${ urlServer }/api/enviar-mail`;
        let formData = new FormData();
        formData.append('token', user.token)
        formData.append('hostMail', watch('hostMail'))
        formData.append('plantilla', watch('plantilla'))
        formData.append('nombre', watch('nombre'))
        formData.append('apellido', watch('apellido'))
        formData.append('empresa', watch('empresa'))
        formData.append('saludo', watch('saludo'))
        formData.append('email', watch('email'))
        formData.append('asunto', watch('asunto'))
        formData.append('guardar', watch('guardar'))
        setLoadingS(true)
        fetch(url, {
            method: 'POST',
            body: formData,
            }).then(response => response.json())
            .then(data => {
                setLoadingS(false)
                if (data.ok) {
                    customSwall.fire({
                        icon: 'success',
                        title: 'Enviado!',
                        text: 'El correo fue enviado correctamente',
                        showConfirmButton: true,
                    }).then((result) => {
                        document.querySelector('.resumen').innerHTML = data.resumen
                    }) 
                } else {
                    customSwall.fire(
                        'Error!',
                        !data.error ? data.error : 'No se pudo enviar este correo, puede ser que no sea válido',
                        'warning'
                        ).then((result) => {
                          return;
                      })
                }
            })
            .catch(error => {
                setLoadingS(false)
                customSwall.fire(
                    'Error!',
                    'No se puede enviar correos, contacta con soporte',
                    'warning'
                    ).then((result) => {
                      console.warn(error)
                  })
            })
    }

    function cambiarToggleGuardar(value) {
        let newData = user;
        newData.settings.guardar_contacto = value
        localStorage.setItem('userData', JSON.stringify(newData))
        setValue('guardar', value);
    }

    return (<>
        { (loading || loadingS) && <Spiner/> }
        <HeaderSection title={'Enviar Correo'} />
        <main className="cont-colunmnas">
            <div className="cont-izquierdo">
                <form id="form-send" onSubmit={ onSubmit }>
                    <p className="p-form-title">Configuracion del correo</p>
                    <div className="cont-items-form">
                        <div className="cont-form-row">
                            <div className="cont-form-item">
                                <label htmlFor="hostMail">Enviar desde:</label>
                                <select { ...register('hostMail', {
                                    onChange: e => updateView(),
                                    required: {
                                        value: true,
                                        message: 'Selecciona el correo'
                                    },
                                }) }>
                                    <option value="">Selecciona el correo</option>
                                    {
                                        data?.agentes?.map(agente => {
                                            return(<option key={ agente.id } value={ agente.id }>{ agente.correo }</option>)
                                        })
                                    }
                                </select>
                                <span>{ !!errors['hostMail'] && errors['hostMail'].message }</span>
                            </div>
                            <div className="cont-form-item">
                                <label htmlFor="email">Destinatario:</label>
                                <input type="email" placeholder="destinatario@mail.com" { ...register('email', {
                                    required: {
                                        value: true,
                                        message: 'Ingresa el correo de destino'
                                    },
                                }) }/>
                                <span>{ !!errors['email'] && errors['email'].message }</span>
                            </div>
                        </div>
                        <div className="cont-form-row">
                            <div className="cont-form-item">
                                <label htmlFor="asunto">Asunto:</label>
                                <input type="text" placeholder="Presentación de servicios" { ...register('asunto', {
                                    required: {
                                        value: true,
                                        message: 'Ingresa el asunto del correo'
                                    },
                                }) }/>
                                <span>{ !!errors['asunto'] && errors['asunto'].message }</span>
                            </div>
                            { tieneVariables &&
                            <div className="cont-form-item">
                                <label htmlFor="saludo">Saludo:</label>
                                <select { ...register('saludo', {
                                    onChange: e => updateView(),
                                    required: false,
                                }) }>
                                    <option value="">Ninguno</option>
                                    <option value="Estimado">Estimado</option>
                                    <option value="Estimada">Estimada</option>
                                    <option value="Estimados">Estimados</option>
                                    <option value="Señores">Señores</option>
                                </select>
                                <span>{ !!errors['saludo'] && errors['saludo'].message }</span>
                            </div>
                            }
                        </div>
                        { tieneVariables && <>
                        <div className="cont-form-row">
                            <div className="cont-form-item">
                                <label htmlFor="nombre">Nombre:</label>
                                <input type="text" placeholder="José" { ...register('nombre', {
                                    onChange: e => updateView(),
                                    required: false,
                                }) }/>
                                <span>{ !!errors['nombre'] && errors['nombre'].message }</span>
                            </div>
                            <div className="cont-form-item">
                                <label htmlFor="apellido">Apellido:</label>
                                <input type="text" placeholder="Perez" { ...register('apellido', {
                                    onChange: e => updateView(),
                                    required: false,
                                }) }/>
                                <span>{ !!errors['apellido'] && errors['apellido'].message }</span>
                            </div>
                        </div>
                        <div className="cont-form-item">
                            <label htmlFor="empresa">Enviar a Empresa:</label>
                            <input type="text" placeholder="Almacenes Tía" { ...register('empresa', {
                                onChange: e => updateView(),
                                required: false,
                            }) }/>
                            <span>{ !!errors['empresa'] && errors['empresa'].message }</span>
                        </div>
                        </>}
                    </div>
                    <Toggle style={{ marginTop: '20px' }} defaultChecked={user.settings.guardar_contacto} onChange={cambiarToggleGuardar}>Guardar este contacto si no existe</Toggle>
                    <button type="submit">Enviar Correo</button>
                </form>
                <div className="cont-resumen">
                    <p className="p-form-title">Reporte de envíos</p>
                    <div className="resumen">
                        <p><b>Ultimo envío: </b><span id="res-ultimo-envio">-- - --</span></p>
                        <p><b>Envios hoy: </b><span id="res-ultimo-envio">--</span></p>
                        <p><b>Total envíos: </b><span id="res-ultimo-envio">--</span></p>
                    </div>
                </div>
            </div>
            <div className="cont-derecho">
                <div className="select-plantilla cont-form-item">
                    <label htmlFor="plantilla">Plantilla</label>
                    <select form="form-send" { ...register('plantilla', {
                        onChange: e => updateView(),
                        required: {
                            value: true,
                            message: 'Selecciona la plantilla a enviar'
                        },
                    }) }>
                        <option value="">Selecciona la plantilla</option>
                        {
                            data?.plantillas?.map(plantilla => {
                                return(<option key={ plantilla.id } value={ plantilla.id }>{ plantilla.nombre }</option>)
                            })
                        }
                    </select>
                    <span>{ !!errors['plantilla'] && errors['plantilla'].message }</span>
                </div>
                {/*<script>
                    let predAsuntos = JSON.parse('[{"asunto":"Presentaci\u00f3n de Servicios","id":"1"}]')
                </script>**/}
                <h2>Vista Previa</h2>
                <iframe src="data:text/html;charset=utf-8,<h1>Selecciona una plantilla</h1>"></iframe>
            </div>
        </main>
    </>)
}
