import { useState } from "react";
import { Outlet } from "react-router-dom"
import SideNavBar from "../components/SideNavBar";
import { Stack } from "rsuite";
import {isMobile} from 'react-device-detect';

const ContentApp = () => {

    const [activeKey, setActiveKey] = useState('1');
    const [openKeys, setOpenKeys] = useState([]);
    const [expanded, setExpand] = useState(isMobile ? false : true);

    return <Stack style={{ height: '100vh' }}>
        <Stack.Item style={{ height: '100vh' }}>
            <SideNavBar
                activeKey={activeKey}
                openKeys={openKeys}
                onSelect={setActiveKey}
                onOpenChange={setOpenKeys}
                expanded={expanded}
                onExpand={setExpand}
                style={{ maxWidth: 250, height: '100%' }}
            />
        </Stack.Item>
        <Stack.Item style={{ padding: 16, width: '100vw', maxWidth: '100vw', height: '100vh', overflowX: 'auto', position: 'relative' }} alignSelf="flex-start">
            <Outlet />
        </Stack.Item>
    </Stack>
}

export default ContentApp