import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom';
import { delateClient, getDatos, updateAgente, updatePrivado } from '../api/getUser';
import { customSwall } from "./CustomSwal"
import Spiner from './Spiner';
import { urlServer } from "../config/config"
import { IconButton, Message, SelectPicker, Table, Toggle, useToaster } from 'rsuite';
import PhoneFillIcon from '@rsuite/icons/PhoneFill';
import EyeRoundIcon from '@rsuite/icons/EyeRound';
import PhoneIcon from '@rsuite/icons/Phone';
import WaitIcon from '@rsuite/icons/Wait';
import EmailFillIcon from '@rsuite/icons/EmailFill';
import CheckOutlineIcon from '@rsuite/icons/CheckOutline';
import MessageIcon from '@rsuite/icons/Message';
import RemindFillIcon from '@rsuite/icons/RemindFill';
import RemindRoundIcon from '@rsuite/icons/RemindRound';
import BlockIcon from '@rsuite/icons/Block';
import EditIcon from '@rsuite/icons/Edit';
import SendIcon from '@rsuite/icons/Send';
import TrashIcon from '@rsuite/icons/Trash';
import LockIcon from '@rsuite/icons/Lock';

const { Column, HeaderCell, Cell } = Table;

export default function ({ clientes, modificar }) {

    const user = JSON.parse(localStorage.getItem('userData'))
    const queryClient = useQueryClient()
    const navigate = useNavigate();
    const toaster = useToaster();
    const fontSize = 12;

    const { data: datosAgentes = [], isLoading: recuperandoAgentes } = useQuery(["usuariosAgents"], () => getDatos('/api/get-data/usuarios', { token: user.token }), {
        refetchOnWindowFocus: true,
        staleTime: 'Infinity',
        retryDelay: 1000
    });

    const { mutate: eliminar, isLoading } = useMutation(delateClient, {
        onSuccess: (data) => {
            if (data.ok) {
                customSwall.fire({
                    icon: 'success',
                    title: `Cliente eliminado!`,
                    text: `Los datos fue eliminado correctamente`,
                    showConfirmButton: true,
                }).then(response => {
                    queryClient.invalidateQueries(["clientes"])
                })
            } else {
                customSwall.fire({
                    icon: 'error',
                    title: 'Error',
                    text: !!data.error ? data.error : 'No se pudo completar la acción, contacta a soporte',
                    showConfirmButton: true,
                })
            }
        },
        onError: e => {
            customSwall.fire({
                icon: 'error',
                title: 'Error',
                text: 'ocurrio un error, contacta a soporte',
                showConfirmButton: true,
            })
            console.error(e)
        }
    })

    function mostrarInfo(id, req) {
        customSwall.fire({
            title: 'Recuperando...',
            html: 'Espera...',
            animation: false,
            backdrop: false,
            didOpen: () => {
                customSwall.showLoading()
                let formData = new FormData()
                formData.append('token', user.token)
                formData.append('cliente', id)
                formData.append('req', req)
                fetch(`${urlServer}/api/base/clientes/info`, {
                    method: 'POST',
                    body: formData,
                })
                    .then((response) => response.json())
                    .then((data) => {
                        customSwall.fire({
                            title: data.title,
                            html: data.html,
                            width: '800px',
                            showConfirmButton: true,
                        })
                    })
                    .catch(error => {
                        customSwall.fire({
                            icon: 'error',
                            title: 'Error',
                            text: 'No se pudo recuperar la informacion',
                            showConfirmButton: true,
                            timer: 2000
                        })
                    })
            }
        })
    }

    const { mutate: fetchAsiganarAgente, isLoading: asignando } = useMutation(updateAgente, {
        onSuccess: (data) => {
            if (data.ok) {
                toaster.push(<Message showIcon type='success' closable>
                    <strong>Actualizado!</strong> Agente actualizado para este cliente.
                </Message>, { placement: 'bottomCenter', duration: 5000 })
            } else {
                toaster.push(<Message showIcon type='error' closable>
                    <strong>Error!</strong> {data.error ?? 'Ocurrió un error al asignar el agente'}
                </Message>, { placement: 'bottomCenter', duration: 5000 })
            }
        }
    })

    const { mutate: setPrivado, isLoading: settingPrivado } = useMutation(updatePrivado, {
        onSuccess: (data) => {
            if (data.ok) {
                toaster.push(<Message showIcon type='success' closable>
                    <strong>Actualizado!</strong> Se actualizo la información correctamente.
                </Message>, { placement: 'bottomCenter', duration: 5000 })
            } else {
                toaster.push(<Message showIcon type='error' closable>
                    <strong>Error!</strong> {data.error ?? 'Ocurrió un error al actualizar la información'}
                </Message>, { placement: 'bottomCenter', duration: 5000 })
            }
        }
    })

    function mostrarTelefonos(correo, telefonos) {
        let textoTelefonos = JSON.parse(telefonos).join(', ');
        customSwall.fire({
            title: `Telefonos de: ${correo}`,
            html: textoTelefonos,
            width: '800px',
            showConfirmButton: true,
        })
    }

    function showEliminar(cliente) {
        customSwall.fire({
            icon: 'question',
            title: `Eliminar Cliente?`,
            html: `<p>Estas seguro? esta acción no se puede revertir</p><br><br><p><b>Eliminar: </b>${cliente.correo}</p>`,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Si, eliminar'
        }).then(response => {
            if (response.isConfirmed) {
                eliminar({ token: user.token, cliente: cliente.id })
            }
        })
    }

    return (<>
        {isLoading && <Spiner />}
        <Table
            style={{ marginTop: 20 }}
            bordered
            height={700}
            data={clientes}
            cellBordered
        >
            <Column width={60} align="center" fixed>
                <HeaderCell>ID</HeaderCell>
                <Cell dataKey="id" style={{ fontSize: `${fontSize}px` }} />
            </Column>

            <Column width={100} fullText>
                <HeaderCell>Nombre</HeaderCell>
                <Cell dataKey="nombre" style={{ fontSize: `${fontSize}px` }} />
            </Column>

            <Column width={100} fullText>
                <HeaderCell>Apellido</HeaderCell>
                <Cell dataKey="apellido" style={{ fontSize: `${fontSize}px` }} />
            </Column>

            <Column width={200} fullText>
                <HeaderCell>Empresa</HeaderCell>
                <Cell dataKey="empresa" style={{ fontSize: `${fontSize}px` }} />
            </Column>

            <Column width={200} fullText>
                <HeaderCell>Cargo</HeaderCell>
                <Cell dataKey="cargo" style={{ fontSize: `${fontSize}px` }} />
            </Column>

            <Column width={350} fullText>
                <HeaderCell>Correo</HeaderCell>
                <Cell dataKey="correo" style={{ fontSize: `${fontSize}px` }} />
            </Column>

            <Column width={100}>
                <HeaderCell title='Teléfonos' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><PhoneIcon /></HeaderCell>
                <Cell style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: `${fontSize}px` }}>
                    {rowData => {
                        const telefonos = JSON.parse(rowData.telefonos);
                        return (telefonos.length > 1) ? <IconButton onClick={() => mostrarTelefonos(rowData.correo, rowData.telefonos)} icon={<EyeRoundIcon />} /> : (
                            telefonos.length === 1 ? telefonos[0] : <></>
                        )
                    }}
                </Cell>
            </Column>

            <Column width={40} fixed='right'>
                <HeaderCell title='Llamada Realizada' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><PhoneFillIcon /></HeaderCell>
                <Cell style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {rowData => {
                        const tieneInfoLlamada = parseInt(rowData.cont_llamada)
                        return tieneInfoLlamada ? <CheckOutlineIcon onClick={() => mostrarInfo(rowData.id, 'cont_llamada')} style={{ cursor: 'pointer' }} color='var(--verde)' />
                            : <WaitIcon color='var(--gris)' />
                    }}
                </Cell>
            </Column>

            <Column width={40} fixed='right'>
                <HeaderCell title='Correo Enviado' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><EmailFillIcon /></HeaderCell>
                <Cell style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {rowData => {
                        const tieneInfoCorreo = parseInt(rowData.cont_correo)
                        return tieneInfoCorreo ? <CheckOutlineIcon onClick={() => mostrarInfo(rowData.id, 'cont_correo')} style={{ cursor: 'pointer' }} color='var(--verde)' />
                            : <WaitIcon color='var(--gris)' />
                    }}
                </Cell>
            </Column>

            <Column width={40} fixed='right'>
                <HeaderCell title='Lee Correos' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><MessageIcon /></HeaderCell>
                <Cell style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {rowData => {
                        const abreCorreos = parseInt(rowData.lee_correos)
                        return abreCorreos ? <CheckOutlineIcon color='var(--verde)' />
                            : <WaitIcon color='var(--gris)' />
                    }}
                </Cell>
            </Column>

            <Column width={40} fixed='right'>
                <HeaderCell title='Observaciones' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><RemindFillIcon /></HeaderCell>
                <Cell style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {rowData => {
                        const hayObservacion = rowData.observaciones !== ''
                        return hayObservacion ? <RemindRoundIcon color='var(--naranja)' onClick={() => mostrarInfo(rowData.id, 'observaciones')} style={{ cursor: 'pointer' }} />
                            : <BlockIcon color='var(--gris)' />
                    }}
                </Cell>
            </Column>

            <Column width={user.role === 'admin' ? 180 : 145} fixed='right'>
                <HeaderCell style={{ display: 'flex', justifyContent: 'center' }}>Acciones</HeaderCell>
                <Cell style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                    {rowData => {
                        return <>
                            <IconButton appearance='primary' title='Editar información' icon={<EditIcon />} onClick={() => modificar(rowData.id)} />
                            <IconButton appearance='primary' color="green" title='Enviar Correo' icon={<SendIcon />} onClick={() => navigate(`/enviar-correo?send=${rowData.correo}&name=${rowData.nombre}&last_name=${rowData.apellido}&empresa=${rowData.empresa}`)} />
                            {user.role === 'admin' && <IconButton appearance='primary' color='red' title='Eliminar Cliente' icon={<TrashIcon />} onClick={() => showEliminar(rowData)} />}
                        </>
                    }}
                </Cell>
            </Column>

            {user.role === 'admin' && <Column width={150} fixed='right'>
                <HeaderCell title='Observaciones' style={{ display: 'flex', justifyContent: 'center' }}>Agente</HeaderCell>
                <Cell style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {rowData => {
                        const data = [{ id: 0, correo: 'Sin Asignar' }, ...datosAgentes].map(
                            item => ({ label: item.correo, value: item.id })
                        );
                        return <SelectPicker
                            data={data}
                            searchable={false}
                            style={{ width: 150 }}
                            defaultValue={parseInt(rowData.agente)}
                            loading={recuperandoAgentes || asignando}
                            cleanable={false}
                            placement="bottomEnd"
                            onChange={value => fetchAsiganarAgente({ token: user.token, cliente: rowData.id, agente: value })}
                        />
                    }}
                </Cell>
            </Column>}

            {user.role === 'admin' && <Column width={80} fixed='right'>
                <HeaderCell title='Cliente Privado?' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><LockIcon /></HeaderCell>
                <Cell style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {rowData => (<Toggle
                        loading={settingPrivado}
                        defaultChecked={parseInt(rowData.privado)}
                        onChange={(value) => setPrivado({ token: user.token, cliente: rowData.id, privado: value })}
                    />)}
                </Cell>
            </Column>}

        </Table>
    </>)
}