import { useState } from 'react';
import { useQuery } from 'react-query'
import { getClientsData, getDatos } from '../api/getUser';
import Spiner from '../components/Spiner';
import { customSwall } from '../components/CustomSwal';
import { urlServer } from '../config/config';
import '../assets/css/bases.css'
import ModalCliente from '../components/ModalCliente';
import HeaderSection from '../components/utils/HeaderSection';
import { Button, Input, InputGroup, SelectPicker, Stack, useBreakpointValue } from 'rsuite';
import TablaNewBase from '../components/TablaNewBase';

function Buttons({ numMostrar, pagActiva, setPagActiva, totalP }) {

    const paginas = Math.ceil(totalP / numMostrar)
    const rangoPaginas = () => {
        let pags = []
        if (paginas > 7) {
            let inicio = 0;
            if (pagActiva <= 4) {
                inicio = 0;
            } else {
                inicio = pagActiva - 4;
            }
            let fin = 7;
            if (pagActiva > 4) {
                fin = pagActiva + 3;
                if (fin > paginas) {
                    fin = paginas;
                    inicio = paginas - 7;
                }
            }
            for (let p = inicio; p < fin; p++) {
                pags.push(p + 1)
            }
        } else {
            for (let p = 0; p < paginas; p++) {
                pags.push(p + 1)
            }
        }
        return pags
    }
    const rango = rangoPaginas()

    return (<>
        {paginas > 1 && <>
            {pagActiva > 1 ? <button className="btn-pag btn-terceario" onClick={() => setPagActiva(pagActiva - 1)}>Anterior</button> : <button className="btn-pag btn-hidden">Anterior</button>}
            {
                rango.map((p, k) => {
                    return (<button key={p} className={`btn-pag ${pagActiva === p ? 'btn-primario' : 'btn-cuaternario'}`} onClick={() => setPagActiva(p)}>{p}</button>)
                })
            }
            {pagActiva < paginas ? <button className="btn-pag btn-terceario" onClick={() => setPagActiva(pagActiva + 1)}>Siguiente</button> : <button className="btn-pag btn-hidden">Siguiente</button>}
            {paginas > 7 && <> {pagActiva === paginas ?
                <button className="btn-pag btn-terceario" onClick={() => setPagActiva(1)}>Inicio</button> :
                <button className="btn-pag btn-terceario" onClick={() => setPagActiva(paginas)}>Ultima</button>
            }</>}
        </>}
    </>)
}

export default function BaseDatos() {

    const [modal, setModal] = useState(false)
    const [accionModal, setAccionModal] = useState('guardar')
    const [valuesMod, setvaluesMod] = useState({})
    const [numMostrar, setNumMostrar] = useState(20)
    const [mostrarAgente, setMostrarAgente] = useState(0)
    const [inicio, setInicio] = useState(0)
    const [buscarB, setBuscarB] = useState('')
    const [pagActiva, setPagActiva] = useState(1)
    const user = JSON.parse(localStorage.getItem('userData'))

    const itemsViewsNumber = [20, 50, 100, 250, 500, 1000].map(
        item => ({ label: item, value: item })
      );

    const { data, isLoading } = useQuery(["clientes", [numMostrar, inicio, buscarB, mostrarAgente]], () => getClientsData(user.token, { limit: numMostrar, inicio: inicio, buscar: buscarB, agente: mostrarAgente }), {
        refetchOnWindowFocus: true,
        staleTime: 'Infinity',
        retryDelay: 1000
    });

    const { data: datosAgentes = [], isLoading: recuperandoAgentes } = useQuery(["usuariosAgents"], () => getDatos('/api/get-data/usuarios', { token: user.token }), {
        refetchOnWindowFocus: true,
        staleTime: 'Infinity',
        retryDelay: 1000
    });

    function clickPagina(p) {
        setInicio(numMostrar * (p - 1))
        setPagActiva(p)
    }

    function cambiarMostrar(num) {
        setNumMostrar(num)
        setPagActiva(1)
        setInicio(0)
    }

    function buscar(valor) {
        setPagActiva(1)
        setInicio(0)
        setBuscarB(valor)
    }

    function setAgente(valor) {
        setPagActiva(1)
        setInicio(0)
        setMostrarAgente(valor)
    }

    function itUltimaPagina() {
        const ultimaPagina = Math.ceil(data.total / numMostrar)
        clickPagina(ultimaPagina)
    }

    function modificar(id) {
        let datos = {
            id: id,
            token: user.token
        }
        customSwall.fire({
            title: 'Procesando...',
            html: 'Espera...',
            animation: false,
            backdrop: false,
            didOpen: () => {
                customSwall.showLoading()
                let formData = new FormData()
                for (var key in datos) {
                    formData.append(key, datos[key]);
                }
                fetch(`${urlServer}/api/base/clientes/seleccionar`, {
                    method: 'POST',
                    body: formData,
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.ok) {
                            setAccionModal('modificar')
                            setvaluesMod(data.values)
                            setModal(true)
                            customSwall.fire({
                                text: 'datos listos',
                                backdrop: false,
                                timer: 10,
                                showConfirmButton: false
                            })
                        } else {
                            customSwall.fire({
                                icon: 'error',
                                title: 'Error',
                                text: !!data.error ? data.error : 'ocurrio un error',
                                showConfirmButton: true,
                            })
                        }
                    })
                    .catch(error => {
                        customSwall.fire({
                            icon: 'error',
                            title: 'Error',
                            text: 'ocurrio un error, contacta a soporte',
                            showConfirmButton: true,
                        })
                    })
            }
        })
    }

    const bool600 = useBreakpointValue(
        {
            '(max-width: 600px)': true,
        },
        { defaultValue: false }
    )

    return (<>
        {isLoading && <Spiner />}
        <HeaderSection title={'Base de datos - Branding Studio'} >
            <Stack spacing={10} direction={bool600 ? 'column' : 'row'}>
                {user.role === 'admin' && <InputGroup >
                    <InputGroup.Addon>Ver Agente: </InputGroup.Addon>
                    <SelectPicker searchable={false} data={[{ id: 0, correo: 'Todos' }, ...datosAgentes]?.map((agente) => ({ label: agente.correo, value: agente.id }))} defaultValue={mostrarAgente} cleanable={false} onChange={value => setAgente(value)} />
                </InputGroup>}
                <InputGroup >
                    <InputGroup.Addon>Mostrar: </InputGroup.Addon>
                    <SelectPicker searchable={false} data={itemsViewsNumber} defaultValue={numMostrar} cleanable={false} onChange={value => cambiarMostrar(value)} />
                </InputGroup>
                <InputGroup>
                    <InputGroup.Addon>Buscar: </InputGroup.Addon>
                    <Input onChange={value => buscar(value)} />
                </InputGroup>
                <Button appearance="ghost" onClick={() => { setAccionModal('guardar'); setvaluesMod({}); setModal(true) }}>Nuevo Cliente</Button>
            </Stack>
        </HeaderSection>
        {modal && <ModalCliente cerrar={() => setModal(false)} irUltimaPagina={() => itUltimaPagina()} values={valuesMod} accion={accionModal} />}
        <TablaNewBase clientes={data?.clientes} modificar={(id) => modificar(id)} />
        <section className="footer-base">
            <div className="cont-footer-table-op">
                <div id="btns-pages" className="cont-btns-pages container f-iz">
                    {!!data && <Buttons numMostrar={numMostrar} pagActiva={pagActiva} setPagActiva={(p) => clickPagina(p)} totalP={data.total} />}
                </div>
                <div className="f-dr">
                    {!!data && <p>Mostrando {data.clientes.length} clientes de {data.total} en {Math.ceil(data.total / numMostrar)} páginas</p>}
                </div>
            </div>
        </section>
    </>)
}