import { Link } from "react-router-dom";
import Cabecera from "../components/Cabecera";

export default function Inicio() {
    return (<>
        <div className="columna-completa cont-inicio">
            <Link className="option-dash" to="/base-datos">
                <div className="icon-init-card">
                    <svg id="Capa_1" x="0px" y="0px"
                        viewBox="0 0 500 500">
                        <g>
                            <path fill="var(--blanco)" d="M21.2,277.4c2.4-9.4,5.5-18.5,11.6-26.3c10.8-13.9,24.9-22.3,42.5-24c14.7-1.4,29.3-1.6,43.8,1.9c6.9,1.7,13,4.9,18.8,8.9c2.6,1.8,3.3,3.3,0.8,5.9c-23,24.3-33.5,53.6-35.4,86.6c-1.4,25.2-0.4,50.4-0.5,75.7c0,4.3-1.5,5.3-5.3,5.3c-15.4-0.1-30.9-0.1-46.3-0.1c-16.1,0-24.4-5.8-30-20.7C21.2,352.9,21.2,315.2,21.2,277.4z"/>
                            <path fill="var(--blanco)" d="M370.1,361.5c0,14.8,0.6,29.7,0.1,44.5c-0.7,23-12.5,31.7-31.5,33c-30,2.2-59.9,1.6-89.9,1.1c-29.4-0.5-58.8-0.1-88.2-0.3c-16-0.1-29.4-11.4-31.5-26.7c-0.9-6.7-0.8-13.6-0.9-20.5c-0.2-16.8-0.5-33.6-0.1-50.3c0.4-17.9,2.5-35.7,10.1-52.1c16.1-34.8,43.9-54.8,81.2-62c16.9-3.2,33.9-2.6,51-1.2c48,4.1,86.3,37.3,97.2,84.3c1.6,6.7,2.5,13.4,2.5,20.3C370,341.6,370,351.6,370.1,361.5z"/>
                            <path fill="var(--blanco)" d="M327.3,119.3c0,43.2-34.7,78.1-77.7,78.1c-43.3,0-78-34.8-78-78.3c0-43,35.1-78.1,77.9-78C292.4,41.2,327.3,76.2,327.3,119.3z"/>
                            <path fill="var(--blanco)" d="M477.9,333.9c0,16.5,0,33,0,49.5c-0.1,15.8-11.6,27.8-27.4,28c-16.3,0.2-32.7,0-49,0.1c-4.3,0-5.5-1.4-5.4-5.7c0.3-22.1,0.6-44.3,0-66.4c-0.6-26.9-5.4-52.9-20.6-75.9c-4.3-6.6-9.2-12.7-14.5-18.6c-3.7-4.1-3.6-4.3,1.1-7.6c9.9-7,21.2-10,33.1-10.5c11.7-0.5,23.5-1,35.1,1.4c27.1,5.5,47.5,30.3,47.6,58.1C478,302.1,477.9,318,477.9,333.9z"/>
                            <path fill="var(--blanco)" d="M141.8,148.1c-0.1,27.2-22.2,49.3-49.4,49.3c-27.3,0-49.3-22.3-49.3-49.9c0-27.2,22.4-49.4,49.4-49.4C119.9,98.3,141.9,120.6,141.8,148.1z"/>
                            <path fill="var(--blanco)" d="M357,147.8c0-27.3,21.9-49.4,49.1-49.6c27.1-0.2,49.5,22.2,49.5,49.7c0,27.4-22.2,49.7-49.6,49.6C379,197.3,357,175.1,357,147.8z"/>
                        </g>
                    </svg>
                </div>
                <p className="title-init-card">Lista de Clientes</p>
            </Link>
            <Link className="option-dash" to="/enviar-correo">
                <div className="icon-init-card">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-30 0 600 512"><path fill="var(--blanco)" d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z"/></svg>
                </div>
                <p className="title-init-card">Enviar Plantilla</p>
            </Link>
            <Link className="option-dash" to="/campanas">
                <div className="icon-init-card">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="var(--blanco)" d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0l57.4-43c23.9-59.8 79.7-103.3 146.3-109.8l13.9-10.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176V384c0 35.3 28.7 64 64 64H360.2C335.1 417.6 320 378.5 320 336c0-5.6 .3-11.1 .8-16.6l-26.4 19.8zM640 336a144 144 0 1 0 -288 0 144 144 0 1 0 288 0zm-76.7-43.3c6.2 6.2 6.2 16.4 0 22.6l-72 72c-6.2 6.2-16.4 6.2-22.6 0l-40-40c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L480 353.4l60.7-60.7c6.2-6.2 16.4-6.2 22.6 0z"/></svg>
                </div>
                <p className="title-init-card">Campañas</p>
            </Link>
            <Link className="option-dash option-dash-disabled" to="#" > {/*to="/cotizaciones"**/}
                <div className="icon-init-card">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-110 0 600 512"><path fill="var(--blanco)" d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM64 80c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16zm128 72c8.8 0 16 7.2 16 16v17.3c8.5 1.2 16.7 3.1 24.1 5.1c8.5 2.3 13.6 11 11.3 19.6s-11 13.6-19.6 11.3c-11.1-3-22-5.2-32.1-5.3c-8.4-.1-17.4 1.8-23.6 5.5c-5.7 3.4-8.1 7.3-8.1 12.8c0 3.7 1.3 6.5 7.3 10.1c6.9 4.1 16.6 7.1 29.2 10.9l.5 .1 0 0 0 0c11.3 3.4 25.3 7.6 36.3 14.6c12.1 7.6 22.4 19.7 22.7 38.2c.3 19.3-9.6 33.3-22.9 41.6c-7.7 4.8-16.4 7.6-25.1 9.1V440c0 8.8-7.2 16-16 16s-16-7.2-16-16V422.2c-11.2-2.1-21.7-5.7-30.9-8.9l0 0 0 0c-2.1-.7-4.2-1.4-6.2-2.1c-8.4-2.8-12.9-11.9-10.1-20.2s11.9-12.9 20.2-10.1c2.5 .8 4.8 1.6 7.1 2.4l0 0 0 0 0 0c13.6 4.6 24.6 8.4 36.3 8.7c9.1 .3 17.9-1.7 23.7-5.3c5.1-3.2 7.9-7.3 7.8-14c-.1-4.6-1.8-7.8-7.7-11.6c-6.8-4.3-16.5-7.4-29-11.2l-1.6-.5 0 0c-11-3.3-24.3-7.3-34.8-13.7c-12-7.2-22.6-18.9-22.7-37.3c-.1-19.4 10.8-32.8 23.8-40.5c7.5-4.4 15.8-7.2 24.1-8.7V232c0-8.8 7.2-16 16-16z"/></svg>
                </div>
                <p className="title-init-card">Cotizaciones</p>
            </Link>
            <Link className="option-dash option-dash-disabled" to="#" > {/*to="/lista-de-productos"**/}
                <div className="icon-init-card">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="var(--blanco)" d="M326.3 218.8c0 20.5-16.7 37.2-37.2 37.2h-70.3v-74.4h70.3c20.5 0 37.2 16.7 37.2 37.2zM504 256c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-128.1-37.2c0-47.9-38.9-86.8-86.8-86.8H169.2v248h49.6v-74.4h70.3c47.9 0 86.8-38.9 86.8-86.8z"/></svg>
                </div>
                <p className="title-init-card">Lista de Productos</p>
            </Link>
        </div>
    </>)
}