import { ButtonToolbar } from "rsuite"

const HeaderSection = ({ children, title }) => {
    return <div className="header-seccion" style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h4>{ title }</h4>
        <ButtonToolbar className="toolbar-header">
            { children }
        </ButtonToolbar>
    </div>
}

export default HeaderSection