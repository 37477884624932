import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form'
import { useQuery, useQueryClient } from 'react-query'
import { getUserId } from '../api/getUser';
import Spiner from './Spiner';
import { customSwall } from './CustomSwal';

import logoBranding from '../assets/images/b-logo-blanco.webp'

function MainLogin() {

    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        defaultValues: {
          user: "",
          pass: ''
        },
    })
    
    const { refetch, isFetching } = useQuery("userData", () => getUserId({ pass: watch('pass'), user: watch('user') }), {
        refetchOnWindowFocus: false,
        enabled: false, // disable this query from automatically running
        onSuccess: data => {
            console.log(data);
            if (data.ok) {
                localStorage.setItem('userData', JSON.stringify(data))
                //vigate(`/inicio`)
                window.location.reload(false)
            } else {
                customSwall.fire({
                    title: 'Error!',
                    text: data['error'] ?? 'El usuario o la contraseña estan incorrectos',
                    icon: 'error',
                    confirmButtonText: 'OK'
                }).then(result => {
                    document.querySelector('form').reset();
                });
            }
        }
    });

    const onSubmit = handleSubmit(data => {
        queryClient.removeQueries({ queryKey: ['userData'] })
        refetch()
    })

    /*useEffect(() => {
        if (!!data) {
            if (!data.error) {
                localStorage.setItem('userData', JSON.stringify(data))
                navigate(`/inicio`)
            } else {
                customSwall.fire({
                    title: 'Error!',
                    text: data['error'] ?? 'El usuario o la contraseña estan incorrectos',
                    icon: 'error',
                    confirmButtonText: 'OK'
                }).then(result => {
                    document.querySelector('form').reset();
                });
            }
        }
    }, [data])*/

    return(
        <div className="cont-form-login">
            { isFetching && <Spiner/> }
            <img src={ logoBranding } alt="logo"/>
            <form onSubmit={ onSubmit }>
                <div className="cont-form-item">
                    <label htmlFor="user">Usuario:</label>
                    <input type="text" placeholder="Usuario" { ...register('user', {
                        required: {
                            value: true,
                            message: 'ingresa el usuario'
                        },
                    }) }/>
                    <span>{ !!errors['user'] && errors['user'].message }</span>
                </div>
                <div className="cont-form-item">
                    <label htmlFor="pass">Contraseña:</label>
                    <input type="password" placeholder="12345" { ...register('pass', {
                        required: {
                            value: true,
                            message: 'ingresa la contraseña'
                        },
                    }) }/>
                    <span>{ !!errors['pass'] && errors['pass'].message }</span>
                </div>
                <button type="submit">Acceder</button>
            </form>
        </div>
    )
}

export default MainLogin