import { Sidenav, Nav } from 'rsuite';
import Exit from '@rsuite/icons/legacy/Exit';
import { useNavigate } from 'react-router-dom';
import { AppSelect, Icon, Peoples, Project, Tag } from '@rsuite/icons';
import { isMobile } from 'react-device-detect';
import { IsotipoLogo } from './utils/logosBranding';

const SideNavBar = ({ appearance, openKeys, expanded, onOpenChange, onExpand, style, services, ...navProps }) => {

  const navigate = useNavigate()
  const user = JSON.parse(localStorage.getItem('userData'))

  function logout() {
    window.localStorage.removeItem('userData');
    window.location.reload(false)
  }

  const styleItems = {
    textAlign: 'start',
    fontSize: '12px'
  }

  return (
    <Sidenav
      style={style}
      appearance={appearance}
      expanded={expanded}
      openKeys={openKeys}
      onOpenChange={onOpenChange}
    >
      <Sidenav.Body>
        <Nav {...navProps}>
          <Nav.Item eventKey="1" active icon={<Icon as={IsotipoLogo} />} as={'button'} onSelect={() => navigate(`/`)} style={{ fontWeight: 600, textAlign: 'start' }}>
            Branding Studio
            <p style={{ fontSize: '12px', fontWeight: 400, color: 'black' }}>{user?.nombre} {user?.apellido}</p>
          </Nav.Item>
          <Nav.Item eventKey="2" as={'button'} icon={<Peoples />} onSelect={() => navigate(`/base-datos`)} style={{ textAlign: 'start' }}>Base Clientes</Nav.Item>
          <Nav.Menu eventKey="3" icon={<AppSelect />} title="Correos">
            <Nav.Item eventKey="3-1" as={'button'} onSelect={() => navigate(`/enviar-correo`)} style={styleItems}>Envio Plantilla</Nav.Item>
            <Nav.Item eventKey="3-2" as={'button'} onSelect={() => navigate(`/campanas`)} style={styleItems}>Campañas</Nav.Item>
          </Nav.Menu>
          <Nav.Item eventKey="4" icon={<Exit />} onSelect={logout} as={'button'} style={{ textAlign: 'start', color: 'red' }}>
            Cerrar sesión
          </Nav.Item>
        </Nav>
      </Sidenav.Body>
      {!isMobile && <Sidenav.Toggle onToggle={onExpand} />}
    </Sidenav>
  );
};

export default SideNavBar